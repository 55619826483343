<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Sertifikasi
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="NIP" rules="required">
                <b-form-group label="NIP" label-for="employee_nip" :state="errors.length > 0 ? false : null">
                  <v-select id="employee_nip" v-model="dataParent.employee_nip"
                    :reduce="(employee_nip) => employee_nip.nip" placeholder="Pilih Pegawai"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Tanggal Sertifikasi" label-for="date">
                <validation-provider #default="{ errors }" name="Tanggal Sertifikasi" rules="required">
                  <b-form-input id="date" v-model="dataParent.date" type="date"
                    :state="errors.length > 0 ? false : null" placeholder="21-05-1992" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="type" rules="required">
                <b-form-group label="Tipe Sertifikasi" label-for="type" :state="errors.length > 0 ? false : null">
                  <v-select id="type" v-model="dataParent.type" :reduce="(type) => type.real_id"
                    placeholder="Pilih Type Sertifikasi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listcertificationtypes" label="certification_type_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Bidang" label-for="field">
                <validation-provider #default="{ errors }" name="Bidang" rules="required">
                  <b-form-input id="field" v-model="dataParent.field" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Bidang" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="SK Sertifikasi" label-for="decree">
                <validation-provider #default="{ errors }" name="SK Sertifikasi" rules="required">
                  <b-form-input id="decree" v-model="dataParent.decree" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="SK Sertifikasi" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      listPegawai: [],
      listcertificationtypes: [],
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        employee_nip: '',
        date: '',
        type: '',
        field: '',
        decree: '',
      },
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
  },
  created() {
    this.getpegawai(),
      this.getcertification()
  },
  methods: {
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getcertification() {
      try {
        const response = await this.$http.get('/certificationtypes')
        this.listcertificationtypes = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/employeecertifications', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Sertifikasi Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-sertifikasi' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Sertifikasi Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
